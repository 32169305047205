<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #FFF">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #FFF">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog
      v-model="addUserDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <AddOfficer @stepper="winStepper" />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- <v-dialog
      v-model="editUserDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' ||
        $vuetify.breakpoint.name == 'sm' ||
        $vuetify.breakpoint.name == 'md'
          ? '100vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '75vw'
          : '50vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <EditOfficer
              v-bind:storage="editUserData"
              @stepper="winStepper"
              :key="editUserData._id"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog> -->
    <v-layout wrap justify-center pt-3 px-2>
      <v-flex  align-self-center xs6 sm9 md10 text-left >
        <span class="itemHeading1"> Officers </span>
      </v-flex>
         <v-flex align-self-center xs6 sm3 md2 lg2 xl1 px-3   >
        <v-btn
          block
          tile
          depressed
          outlined
          color="#000000"
          :ripple="false"
          @click="addUserDialog = true"
          class="itemValue"
        >
          Add Officer
        </v-btn>
      </v-flex>
    </v-layout>
  
    <v-layout wrap justify-start py-4>
      <template v-for="(item, i) in officers">
        <v-flex xs12 xl6 pa-2 :key="i">
          <OfficerItem v-bind:storage="item" @stepper="winStepper" />
        </v-flex>
      </template>
      <v-flex xs12 v-if="officers.length < 1">
        <span class="itemKey1"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import OfficerItem from "./officersItem";
import AddOfficer from "./addOfficer";
// import EditOfficer from "./editOfficer";
export default {
  components: {
    OfficerItem,
    AddOfficer,
    // EditOfficer,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    //   users: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      addUserDialog: false,
      editUserData: {},
      officers:[]
    };
  },
 
   beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/official/added/accounts/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
         {   this.officers = response.data.data;
          this.appLoading = false;
}
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.type == "Add") {
        if (windowData.getData) this.getData();
        if (!windowData.addUser) this.addUserDialog = false;
      }
      //  else if (windowData.type == "Edit") {
      //   if (windowData.userData) this.editUserData = windowData.userData;
      //   if (windowData.getData) this.getData();
      //   this.editUserDialog = windowData.editUser;
      // }
       else if (windowData.type == "Suspend") {
        if (windowData.pageResponse.status) {
          this.msg = "User Suspended Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not SUspended";
          this.showSnackBar = true;
        }
      }
       else if (windowData.type == "Activate") {
        if (windowData.pageResponse.status) {
          this.msg = "User Activated Successfully";
          this.showSnackBar = true;
          if (windowData.getData) this.getData();
        } else {
          this.msg = "User not Activateds";
          this.showSnackBar = true;
        }
      }
    },
  },
};
</script>